.header {
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all .3s;

    &.header--up {
        top: -100px;
    }

    &.header--solid {
        background-color: white;
        box-shadow: $shadow-thin;
    }

    &.header--tp {
        background-color: transparent;
        box-shadow: none;
    }

    &--back-menu {
        // background-color: white;
    }
    
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
    }

    &__left {

    }
    
    &__right {
        height: 24px;

        ._icon-right {
            color: $color-base;

            i {
                
            }
        }
    }

    &__back {
        border: 0;
        background-color: transparent;
        outline: none !important;
        transition: all .5s $cbzr;
        
        i {
            font-size: 18px;
        }

        &._icon-white {
            color: white;
        }
    }

    &__logo {
        height: 40px;

        g {
            path {
                transition: all .5s $cbzr;
            }
        }

        &._logo-white {
            g {
                path {
                    @media (max-width: 1199.98px) {
                        fill: white;
                    }
                }
            }
        }


    }

    &__drawer-btn {
        padding: 0;
        cursor: pointer;
        width: 26px;
        height: 24px;
        background-color: transparent;
        border: none;
        text-align: center;
        outline: none !important;
        transition: all .5s $cbzr;
        position: relative;
        top: -6px;
        
        > div {
            position: absolute;
            align-items: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 3px;
            background-color: $color-primary;
            width: 100%;
            border-radius: 32px;
            transition: all .5s $cbzr;

            &::after,
            &::before {
                position: absolute;
                display: inline-block;
                content: '';
                height: 3px;
                width: 100%;
                background-color: $color-primary;
                transform-origin: center;
                border-radius: 32px;
                transition: all .5s $cbzr;
                
            }

            &::after {
                bottom: -7px;
                left: 0;
            }

            &::before {
                top: -7px;
                left: 0;
            }
        }

        > span {
            position: relative;
            bottom: -16px;
            left: -1px;
            font-size: 11px;
            font-weight: bold;
            transition: all .2s ease-in-out;
        }

        &._active {

            > div {
                background-color: transparent;

                &::after,
                &::before {
                    background-color: white;
                }
    
                &::after {
                    transform: rotate(45deg);
                    bottom: 0;
                }
    
                &::before {
                    transform: rotate(-45deg);
                    top: 0;
                }
            }

            > span {
                color: white;
            }

            @media (min-width: 1300px) {

                > div {
                    &::after,
                    &::before {
                        background-color: $color-primary;
                    }
                }
    
                > span {
                    color: $color-primary;
                }
            }


        }
    }
}

.drawer {
    position: fixed;
    z-index: 98;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    transition: all .5s $cbzr;

    @media (min-width: 1200px) {
        width: 322px;
    }

    &__sc {
        min-height: calc(100vh - 60px);
    }

    &__link {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        // padding-top: 134px;
        padding-top: 84px;

        li {

            // &:not(:last-child) {
                margin-bottom: 34px;

                @media (max-width: 350px) {
                    margin-bottom: 20px;
                }
            // }

            a {
                font-family: $fontGlacial;
                font-size: 20px;
                color: white;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    &._show {
        right: 0;
    }
}