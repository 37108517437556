// COLORS
$color-base: #474544;
$color-base-light: #EFEFEF;
$color-base-light2: #DDDDDD;
$color-primary: #851B06;
$color-primary-light: #F8D8DE;
$color-bg-pattern : #FCFCED;
$color-bdr : #CDCDCD;

// SEMANTIC COLORS
$color-success: #42dc85;
$color-info: #1d91f4;
$color-warning: #ffb627;
$color-error: #e52a34;


// FONT-FAMILY
$fontGlacial: 'Glacial';
$fontHiraginoGothic: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontHiraginoMincho: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$fontArial: Arial, "Helvetica Neue", Helvetica, sans-serif;
$fontCentury: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
$fontYuGothic: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontYuMincho: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$fontTsukushiAGothic: "Tsukushi A Round Gothic","筑紫A丸ゴシック";
$fontTsukushiBGothic: "Tsukushi B Round Gothic","筑紫B丸ゴシック";
$fontMeryo: "メイリオ","Meiryo";
$fontNotoSans: "Noto Sans CJK JP";
$fontNotoSerif: "Noto Serif CJK JP";
$fontHiraginoGothicStd: "ヒラギノ角ゴ Std","Hiragino Kaku Gothic Std";

$shadow: 0px 2px 3px #00000029;
$shadow-alt: 0px 3px 6px #00000029;
$shadow-thin: 0px 0px 2px #00000029;

// ANIMATION
$cbzr: cubic-bezier(0.42, 0, 0.03, 1.01);
$cbzr-bounce: cubic-bezier(0.93, -0.37, 0.1, 1.42)