@import 'variable';

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&family=Roboto:wght@300;400;700&display=swap');

@font-face {
    font-family: 'Glacial';
    src: url("../fonts/GlacialIndifference-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Glacial';
    font-weight: bold;
    src: url("../fonts/GlacialIndifference-Bold.otf") format("opentype");
}

// Reset Bootstrap Container
.container {
    padding-left: 36px;
    padding-right: 36px;

    @media (max-width: 350px) {
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

h1 {
    font-size: 20px;
}

button {
    outline: none !important;
}

body {
    font-family: "Quicksand", sans-serif;
    color: $color-base;
    font-size: 16px;
}

.no-overflow {
    overflow: hidden;
}

a {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}