@import 'variable';

// Loading
 @keyframes loading {
    0% {
        transform: rotate(0);
    }
    30% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  
@keyframes progressBtn{
  0% {
    transform:  rotate(0deg) ;
  }
  90% {
      transform:  rotate(0deg) ;
    }
  92% {
    transform:  rotate(15deg) ;
  }
  94% {
    transform:  rotate(-10deg) ;
  }
  96% {
    transform:  rotate(5deg) ;
  }
  98% {
    transform:  rotate(-5deg) ;
  }
  100% {
    transform:  rotate(0deg) ;
  }
}

.element-animation{
  animation: splash linear 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: splash linear 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: splash linear 3s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: splash linear 3s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: splash linear 3s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

@keyframes splash{
  0% {
    opacity:1;
    transform: scaleX(1.00) scaleY(1);
  }
  10% {
    opacity:0;
    transform: scaleX(1.5) scaleY(1.5);
  }
  38% {
    opacity:0;
    transform: scaleX(1.5) scaleY(1.5);
  }
  100% {
    opacity:0;
    transform: scaleX(1) scaleY(1);
  }
}