@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';

@import 'animations';
@import 'component/sns-bottom';

::selection {
    background-color: $color-primary;
    color: #fff;
}

//BUTTONS
.link-btn {
    display: block;
    height: 42px;
    line-height: 38px;
    width: 100%;
    max-width: 240px;
    border-radius: 21px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    box-shadow: $shadow;
    border: 1px solid;
    transition: all .4s $cbzr;

    &:hover,
    &:active {
        color: inherit;
        outline: none;
    }

    &__center {
        margin-left: auto;
        margin-right: auto;
    }

    &__sm {
        height: 32px;
        line-height: 30px;
        font-size: 14px;
    }

    &__w-circle {
        width: 42px;
    }

    &__w-sm {
        width: 120px;
    }
    
    &__w-md {
        width: 180px;
    }

    &__no-shadow {
        box-shadow: none;
    }

    &__primary {
        background-color: $color-primary;
        color: white;
        border-color: $color-primary;

        &:hover,
        &:active {
            color: white;
        }
    }
    
    &__primary-o {
        background-color: white;
        color: $color-primary;
        border-color: $color-primary;

        &:hover,
        &:active {
            color: $color-primary;
        }
    }

    &__secondary {
        background-color: white;
        color: $color-primary;
        border-color: white;

        &:hover,
        &:active {
            color: $color-primary;
        }
    }

    &__disabled {
        background-color: $color-base-light;
        color: $color-base;
    }
}

//INPUT FORM
select,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="search"] {
    background-color: white;
    border: none;
    border-bottom: 1px solid $color-bdr;
    width: 100%;
    padding-bottom: 10px;
    transition: all .4s $cbzr;

    &:focus {
        outline: none;
        border-bottom: 1px solid $color-primary;
    }
}

input {
    &:read-only,
    &:disabled {
        color: mix($color-base, white, 50%);

        &:focus {
            border-bottom: 1px solid $color-bdr;
        }
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

label {
    font-size: 12px;
    font-weight: bold; 
}

//Hide when drawer showed
.hwds {
    opacity: 1;
    transition: all .4s $cbzr;

    &._true {
        opacity: 0;
    }
}

.other-btn {
    font-size: 14px;
    color: $color-base;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

.avatar-select {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    margin-bottom: 20px;

    input {
        display: none;
    }

    img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
    }
}

.proof-select {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #f8d8de;

    input {
        display: none;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.form-set {
    margin-bottom: 30px;

    h4 {
        font-size: 18px;
        line-height: 1.5;
    }

    ._impt {
        font-weight: bold;
        color: $color-primary;
    }

    > label {
        margin-bottom: 5px;

        ._must {
            color: white;
            background-color: $color-primary;
            border-radius: 10px;
            display: inline-block;
            padding: 0 5px;
            font-size: 10px;
        }

    }

    ._lg {
        font-size: 16px;
    }

    .options {
        display: flex;

        label {
            display: block;

            span {
                display: block;
                border: 1px solid #cdcdcd;
                padding: 8px;
                text-align: center;
                border-radius: 4px;
            }

            input {
                display: none;
                
                &:checked {

                    + span {
                        background-color: $color-primary;
                        color: white;
                        border-color: $color-primary;
                    }
                }
            }
        }
    }

    .answer-list {
        display: flex;
        align-items: center;
        margin-top: 16px;
        position: relative;

        &__option {
            padding-right: 10px;

            label {

                span {
                    display: block;
                    width: 32px;
                    height: 32px;
                    border: 1px solid #cdcdcd;
                    text-align: center;
                    line-height: 30px;
                    font-size: 18px;
                    border-radius: 4px;
                }

                input {
                    display: none;

                    &:checked {

                        + span {
                            background-color: $color-primary;
                            color: white;
                            border-color: $color-primary;
                        }
                    }
                }
            }
        }

        &__text {
            flex: 1;
        }
    }

    &--error {

        input[type="text"],
        input[type="number"],
        input[type="password"],
        input[type="email"],
        input[type="search"] {
            border-color: red;
            color: red;
        }

        sub {
            color: red;
            margin-top: 8px;
            display: block;
        }
    }
}

.form-link {
    font-weight: bold;
    color: $color-primary;

    &:hover,
    &:focus {
        color: $color-primary;
    }
}

.align-right {
    text-align: right;
}

.content-right {
    margin-left: auto;
}

.cbx-set {
    display: flex;
    align-items: center;

    span {
        margin-left: 8px;
        position: relative;
        top: 1px;
    }

    ._box {
        width: 32px;
        height: 20px;
        background-color: mix($color-base, white, 50%);
        position: relative;
        border-radius: 20px;
        transition: all .2s ease-in-out;

        &::after {
            position: absolute;
            display: block;
            content: '';
            width: 20px;
            height: 20px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            border: 2px solid mix($color-base, white, 50%);
            border-radius: 50%;
            transition: all .2s ease-in-out;
        }
    }

    &._center {

        ._box {
            margin-left: auto;
            margin-right: auto;
        }
    }

    input[type="checkbox"] {
        display: none;

        &:checked {

            + ._box {
                background-color: $color-primary;

                &::after {
                    background-color: white;
                    border-color: $color-primary;
                    left: 12px;
                }
            }
        }
    }
}

//
._jp {
    font-family: $fontHiraginoGothic;
}

h1.main-title {
    font-family: $fontGlacial;
    text-align: center;
    font-weight: bold;
    color: $color-primary;
    margin-bottom: 20px;
}

p.body-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;

    ._impt {
        font-weight: bold;
        color: $color-primary;
    }

    @media (max-width: 350px) {
        width: 190px;
        margin-left: auto;
        margin-right: auto;
    }
}

.content-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 154px);
    padding-bottom: 42px;
}

.content-top {
    padding-bottom: 42px;
}


// HEADER
@import 'component/header.scss';
// 
// FOOTER
@import 'component/footer.scss';

// /* COOKIE */
// @import 'component/cookie.scss';

//Summer note
.question-set {

    .add-question {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $color-primary;
        color: white;
        display: inline-block;
        text-align: center;
        line-height: 48px;
        box-shadow: $shadow;
    }
}

.sn-editor-toolbar-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;;
    padding: 9px 10px;
    cursor: pointer;

    ~ .note-editor.note-frame.card {

        .note-toolbar.card-header {
            display: none;
        }

        .note-editing-area {

            .note-editable.card-block {
                padding-right: 25px;
            }
        }
    }
}