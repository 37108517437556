.footer {

    p {
        font-size: 12px;
        color: white;
        margin-bottom: 0;

        @media (max-width: 350px) {
            font-size: 10px;
        }
    }

    &--home {

        .footer {
            &__bottom {
                background-color: $color-primary;
                padding: 8px 0 7px;
                text-align: center; 
            }
        }

    }

    &--main {

        .footer {
            &__bottom {
                background-image: url('../img/misc/footer-grd.svg');
                height: 60px;
                background-size: auto 60px;
                background-position: bottom center;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                text-align: center;

                p {
                    margin-bottom: 7px;
                }
            }
        }
    }

    &--flip {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
}