@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

.sns-bottom {
    padding: 20px 0 10px;
    background-color: $color-base;
    text-align: center;

    a {
        display: inline-block;
        margin: 0 5px;

        img {
            height: 30px;
        }
    }
}